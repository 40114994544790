export const updateConsultationGQL = /* GraphQL */ `
  mutation updateConsultation(
    $input: UpdateConsultationInput!
  ) {
    updateConsultation(input: $input) {
      id
      attorneyJoined
      clientJoined
      dateTimeOccurred
      createdAt
      conversationTranscript
      connectionMethod
      clientPrefferedConnectionMethod
      chatConnectionToken
      actualLength
      lastOnlineAttoney
      lastOnlineClient
      owner
      paymentCaptured
      purchasedLength
      status
      updatedAt
      videoConnectionToken
    }
  }
`
