// import { Home } from "./containers/home";
import Router from './routes'
import ShowToast from './components/ShowToast'
import Loader from './components/Loader'

function App () {
  return (
    <>
      <Router />
      <ShowToast />
      <Loader />
    </>
  )
}

export default App
