import {
  SideBarDiv,
  TopLogo,
  UserDetails,
  UserDetailsBackground,
  UserData,
  UserDataTitle,
  UserDataSeparator,
  ConsultationTimeCont,
  ConsultationTime,
  JoinConsultation,
  MenuDivCon,
  MenuDiv,
  Span,
  Img,
  UserSpan,
  Logo,
  UserImg,
  UserInput,
  UploadOverlay,
  Loader,
  PlusImg,
  MainDiv
} from './styles'
import { useSelector } from 'react-redux'
import user from 'assets/images/userIcon.png'
import plusIcon from 'assets/images/plusIcon.png'
import logo from 'assets/images/logo.png'
import LogOut from 'icons/Logout.svg'
import { useState, useEffect } from 'react'
import { API, graphqlOperation, Storage } from "aws-amplify";
import { updateAttorney } from 'gql/mutations'
import { dispatch } from 'redux/store'
import { _setClientDetail } from 'redux/reducers/userReducer'
import { RotatingLines } from "react-loader-spinner";

const SideBar = ({ logout, active, items, clientDetail, userInfo}) => {
  // const { userInfo } = useSelector(state => state.user)
  const [image, setImage] = useState(null)
  const [loadingAvatar, setLoadingAvatar] = useState(false)
  const [msg, setMsg] = useState('')

  useEffect(() => {
    // if(!image)
    fetchUserAvatar();
  }, [clientDetail])

  async function fetchUserAvatar() {
    if(!clientDetail || !clientDetail.profilePictureUrl) return;
    setImage(await Storage.get(clientDetail.profilePictureUrl))
    setLoadingAvatar(false)
  }
  
  function initFileUpload() {
    document.querySelector('.file-uploader').click();
  }
  const onImageChange = async (e) => {
    setLoadingAvatar(true)
    try {
      // eslint-disable-next-line
      var ext = e.target.files[0].name.match(/\.([^\.]+)$/)[1];
      switch (ext.toLowerCase()) {
        case 'jpg':
        case 'png':
          break;
        default:
          return;
      }

      const storageResult = await Storage.put(`attorneys/profile-images/${clientDetail.id}.${ext}`, e.target.files[0], {
        contentType: 'image/jpeg'
      });

      // await API.graphql(
      //   graphqlOperation(updateAttorney, {
      //     input: {
      //       id: clientDetail.id,
      //       avatarUrl: storageResult.key
      //     },
      //   })
      // );

      // setImage(null);
      dispatch(_setClientDetail({...clientDetail, profilePictureUrl: storageResult.key}))
      // setUploading(false);
      // setProfilePic(null);
      document.querySelector('#profile-avatar').src = URL.createObjectURL(e.target.files[0]);
    }
    catch(error) {
      console.log("!=====catch");
    }
    document.querySelector('.file-uploader').value = null;

  }
  return (
    <SideBarDiv>
      <TopLogo>
        <Logo src={logo} alt='logo' />
      </TopLogo>
      <UserDetails>
      {/* <UserStatus firstName={"firstName"} lastName={"lastName"} /> */}

        <UploadOverlay style={{ position: 'absolute',  zIndex: 2000, top: 110, left: 82 }} onClick={initFileUpload} onMouseEnter={()=>setMsg('Update')} onMouseLeave={()=>setMsg('')}>
          <input
          type="file"
          className="file-uploader"
          // accept="image/x-png,image/jpeg"
          hidden
          onChange={onImageChange}
          />
        </UploadOverlay>
        {
          !image &&  <div style={{ position: 'absolute', zIndex: 3000, top: 120, left: 190}}>
          <PlusImg src={plusIcon} alt='plus' />
        </div>
        }
        <div style={{ color: 'white', fontSize: '16px', fontWeight: 'bold', position: 'absolute', zIndex: 100, top: 170, left: 125}}>{msg}</div>
          
       
        {/* <MainDiv> */}
          <div style={{ position: 'absolute', top: 110, left: 82, }}>
            {
              loadingAvatar
              ?
              <Loader>
                <RotatingLines
                  strokeColor="#91A5BA"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="40"
                  visible={true}
                />
              </Loader>
              :
              <UserImg id="profile-avatar" src={image ? image : user} alt='user' />
            }
          </div>
        {/* </MainDiv> */}
        <UserDetailsBackground>
          <UserData>
            <UserDataTitle>{clientDetail?.firstName} {clientDetail?.lastName}</UserDataTitle>
            <UserSpan>{userInfo.phone_number}</UserSpan>
            <UserSpan>{userInfo.email}</UserSpan>
          </UserData>
          <UserDataSeparator />
        </UserDetailsBackground>
      </UserDetails>
      <ConsultationTimeCont>
        <ConsultationTime>Next Consultation Time:</ConsultationTime><br />
        <JoinConsultation>Tommorow GET HERE</JoinConsultation>
      </ConsultationTimeCont>
      <MenuDivCon>
        {/* <MenuDiv border>
          <Img src={HomeIcon} />
          <Span selected>Home</Span>
        </MenuDiv> */}
        {/* <Img src={Home} /> */}
        {items?.map((item, i) => (
          <MenuDiv onClick={() => active(item)} border key={i}>
            <Img src={item.activeIcon} />
            <Span selected={item.active}>{item.tabName}</Span>
          </MenuDiv>

        ))}
        <MenuDiv onClick={logout}>
          <Img src={LogOut} />
          <Span>Log Out</Span>
        </MenuDiv>
      </MenuDivCon>
    </SideBarDiv>
  )
}
export default SideBar
