import { useEffect, useState } from 'react'
import SideBar from 'components/SideBar'
import { dispatch } from 'redux/store'
import { logOutUser } from 'redux/reducers/userReducer'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import HomeBlueIcon from 'icons/HomeActive.svg'
import HomeGrayIcon from 'icons/Home.svg'

const SidebarContainer = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const logout = () => {
    dispatch(logOutUser())
  }
  const itemsArr = [
    { tabName: 'Home', active: 'true', activeIcon: HomeBlueIcon, blueIcon: HomeBlueIcon, grayIcon: HomeGrayIcon, pathPage: '/home' },
    { tabName: 'New Consultation', active: 'false', activeIcon: HomeGrayIcon, blueIcon: HomeBlueIcon, grayIcon: HomeGrayIcon, pathPage: '/create-new-appointment' }
  ]
  const { clientDetail, userInfo } = useSelector(state => state.user)
  const [items, setItems] = useState(itemsArr)

  const active = (item) => {
    navigatePage(item.pathPage)
    // const newItems = items.map(obj => {
    //   if (obj.tabName === item.tabName) {
    //     obj.active = 'true'
    //     obj.activeIcon = item.blueIcon
    //   } else {
    //     obj.active = 'false'
    //     obj.activeIcon = item.grayIcon
    //   }
    //   return obj
    // })
    // setItems(newItems)
  }

  useEffect(() => {
    const newItems = items.map(obj => {
      if (obj.pathPage === pathname) {
        obj.active = 'true'
        obj.activeIcon = obj.blueIcon
      } else {
        obj.active = 'false'
        obj.activeIcon = obj.grayIcon
      }
      return obj
    })
    setItems(newItems)
  }, [pathname])
  const navigatePage = (path) => {
    navigate(path, { replace: true })
  }


// const onImageChange = (event) => {
//  if (event.target.files && event.target.files[0]) {
//    setImage(URL.createObjectURL(event.target.files[0]));
//  }
// }

// const onImageChange = async (e) => {
//   try {
//     // eslint-disable-next-line
//     var ext = e.target.files[0].name.match(/\.([^\.]+)$/)[1];
//     switch (ext.toLowerCase()) {
//       case 'jpg':
//       case 'png':
//         break;
//       default:
//         return;
//     }
//     const storageResult = await Storage.put(`attorneys/profile-images/${userInfo.id}.${ext}`, e.target.files[0], {
//       contentType: 'image/jpeg'
//     });
//     console.log("!=====storageResult", storageResult);
//     setImage(storageResult.key);
//     document.querySelector('#profile-avatar').src = URL.createObjectURL(e.target.files[0]);
//   }
//   catch(error) {
//   //   errorHandler(error)
//   }
// }

  return (
    <SideBar
    logout={logout}
    navigatePage={navigatePage}
    items={items}
    active={active}
    clientDetail={clientDetail}
    userInfo={userInfo}
    />
  )
}
export default SidebarContainer
