import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// ********** local import components *********
import { closeToast } from 'redux/reducers/commonReducer'
import { dispatch } from 'redux/store'

function ShowToast () {
  const { common } = useSelector((state) => state)
  const { showToastCheck, showToastAttributes } = common

  useEffect(() => {
    const { message, position, type } = showToastAttributes
    showToastCheck &&
      toast.success(message, {
        position,
        type,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      })
    dispatch(closeToast())
  }, [showToastCheck])

  return <ToastContainer />
}
export default ShowToast
