import React from 'react'
import { Outlet } from 'react-router-dom'
import SidebarContainer from 'containers/SidebarContainer'
import { MainLayoutSection, OutletSection, SidebarSection, Container } from './layout.styles'

function Layout () {
  return (
    <Container>
      <MainLayoutSection>

        <SidebarSection>
          <SidebarContainer />
        </SidebarSection>

        <OutletSection>
          <Outlet />
        </OutletSection>

      </MainLayoutSection>
    </Container>
  )
}

export default Layout
