import {
  getTwilioKeys
} from 'services/aws-queries-helper'
import { Client as ConversationsClient } from '@twilio/conversations'
import Video from 'twilio-video'

const createTwilioConversationClient = async (userId) => {
  const twilioKeys = await getTwilioKeys('text', null, userId)
  const jwtToken = twilioKeys.data.getTwilioKeys.jwtToken

  const client = await ConversationsClient.create(jwtToken)
  return client
}

const connectTwilioVideoRoom = async (roomName, userId) => {
  const twilioKeys = await getTwilioKeys('video', roomName, userId)
  const jwtToken = twilioKeys.data.getTwilioKeys.jwtToken

  const room = await Video.connect(jwtToken, {
    name: roomName
  })
  return room
}

const connectTwilioAudioRoom = async (roomName, userId) => {
  const twilioKeys = await getTwilioKeys('voice', roomName, userId)
  const jwtToken = twilioKeys.data.getTwilioKeys.jwtToken

  const localTracks = await Video.createLocalTracks({
    audio: true,
    video: false
  })

  const room = await Video.connect(jwtToken, {
    name: roomName,
    tracks: localTracks
  })
  return room
}

const disconnectTwilioVideoRoom = (prevRoom) => {
  if (prevRoom) {
    prevRoom.localParticipant.tracks.forEach((trackPub) => {
      trackPub.track.stop()
    })
    prevRoom.disconnect()
  }
  return null
}

export {
  createTwilioConversationClient,
  connectTwilioVideoRoom,
  connectTwilioAudioRoom,
  disconnectTwilioVideoRoom
}
