/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_identity_pool_id": "us-east-1:c71c8e0e-e702-407f-8a0e-ee085befc5bd",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_FyrHpTOiA",
  "aws_user_pools_web_client_id": "4d0ho82ccfs0i21rjgrjp4e098",
  "oauth": {
      "domain": "legalqmobileuie1a91d13-e1a91d13-testing.auth.us-east-1.amazoncognito.com",
      "scope": [
          "phone",
          "email",
          "openid",
          "profile",
          "aws.cognito.signin.user.admin"
      ],
      "redirectSignIn": "myapp://",
      "redirectSignOut": "myapp://",
      "responseType": "code"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  "aws_appsync_graphqlEndpoint": "https://qw6pc363cbdmhidvo6q6l7zalm.appsync-api.us-east-1.amazonaws.com/graphql",
  "aws_appsync_region": "us-east-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_cloud_logic_custom": [
      {
          "name": "addUserToGroup",
          "endpoint": "https://vuzxo0o6c2.execute-api.us-east-1.amazonaws.com/testing",
          "region": "us-east-1"
      },
      {
          "name": "quickBloxCustomIdProvider",
          "endpoint": "https://jk5r6tq30k.execute-api.us-east-1.amazonaws.com/testing",
          "region": "us-east-1"
      },
      {
          "name": "uploadPhoto",
          "endpoint": "https://4cvq8164c1.execute-api.us-east-1.amazonaws.com/testing",
          "region": "us-east-1"
      },
      {
          "name": "externalActiveCampaign",
          "endpoint": "https://heqsdwojn9.execute-api.us-east-1.amazonaws.com/testing",
          "region": "us-east-1"
      },
      {
          "name": "manageStripeAccount",
          "endpoint": "https://ub1q658974.execute-api.us-east-1.amazonaws.com/testing",
          "region": "us-east-1"
      }
  ],
  "aws_user_files_s3_bucket": "legalqmobileui9552498daf4145f9a02445e0637102fa94151-testing",
  "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;