import { createSlice } from '@reduxjs/toolkit'
import { API, graphqlOperation } from 'aws-amplify'
import { listFutureConsultations, listImmediateConsultations } from 'graphql/queries'
// import { API, graphqlOperation } from 'aws-amplify'
// import { listBriefFutureConsultations, listBriefPastEngagements } from 'graphql/customQueries'
import moment from 'moment'

import { fetchActiveRequests } from 'services/consultationServices'
import { api } from 'services/restServices'

// ---------- local import components ----------
import { showToast } from './commonReducer'

const initialState = {
  isLoading: false,
  caseCreationLoading: false,
  pastEngagementsList: [],
  immediateOnGoingConsultation: [],
  getFutureEngagements: {},
  attorneyConsultationRequestId: '',
  autoRequestedAttorneyId: '',
  matchedAttorneysNr: null,
  clientDetail: {},
  activeRequests: [],
  futureRequests: []
}

export const ConsultationReducer = createSlice({
  name: 'consultation',
  initialState,
  reducers: {
    _requesAttorneyMatch (state, { payload }) {
      state.attorneyConsultationRequestId = payload.attorneyConsultationRequestId
      state.autoRequestedAttorneyId = payload.autoRequestedAttorneyId
    },

    _caseCreationLoading (state, { payload }) {
      state.caseCreationLoading = payload
    },

    _isLoading (state, { payload }) {
      state.isLoading = payload
    },

    _getConsultationsLoading (state, { payload }) {
      state.getConsultationsLoading = payload
    },

    _caseCreationLoading (state, { payload }) {
      state.caseCreationLoading = payload
    },

    _setActiveRequests (state, { payload }) {
      state.activeRequests = payload
    },

    _pastEngagementsList (state, { payload }) {
      state.pastRequests = payload
    },

    _immediateOnGoingConsultation (state, { payload }) {
      state.immediateOnGoingConsultation = payload
    },

    _allRequests (state, { payload }) {
      state.activeRequests = payload.activeRequests
      state.futureRequests = payload.futureRequests
      state.pastRequests = payload.pastRequests
    }

  }
})

export const {
  _requesAttorneyMatch,
  _isLoading,
  _isLoadingAttorney,
  _caseCreationLoading,
  _createClientDetail,
  _pastEngagementsList,
  _immediateOnGoingConsultation,
  _getFutureEngagements,
  _setActiveRequests,
  _allRequests
} = ConsultationReducer.actions

export const initiateCaseCreationFun = (requestData) => async (dispatch) => {
  dispatch(_caseCreationLoading(true))
  try {
    const response = await api.post('/auto-schedule/create', requestData)
    if (response.data?.attorneyConsultationRequestId) {
      dispatch(_requesAttorneyMatch(response?.data))
      dispatch(_caseCreationLoading(false))
      dispatch(showToast({ type: 'success', message: 'Request attorney match successfully' }))
      return response?.data
    } else {
      dispatch(_caseCreationLoading(false))
      dispatch(showToast({ type: 'error', message: 'Error while request attorney matching' }))
    }
  } catch (err) {
    dispatch(showToast({ type: 'error', message: err }))
    dispatch(_caseCreationLoading(false))
  }
}

// export const getEngagementData = () => async (dispatch) => {
//   dispatch(_isLoadingAttorney(true))
//   API.graphql(graphqlOperation(listBriefPastEngagements))
//     .then((response) => {
//       const incomingPastEngagements = response.data.listPastEngagements.items
//       const pastEngagements = handlePastEngagements(incomingPastEngagements)
//       dispatch(_pastEngagementsList(pastEngagements))
//       dispatch(_isLoadingAttorney(false))
//     })
//     .catch((err) => {
//       handlePastEngagements(
//         err.data.listPastEngagements.items.filter((x) => x)
//       )
//       dispatch(_isLoadingAttorney(false))
//     })

//   API.graphql(
//     graphqlOperation(listBriefFutureConsultations, {
//       filter: {
//         status: {
//           ne: 'Canceled'
//         }
//       }
//     })
//   ).then((response) => {
//     const getFutureEngagements = handleFutureEngagements(
//       response.data.listFutureConsultations.items
//     )
//     dispatch(_getFutureEngagements(getFutureEngagements))
//     dispatch(_isLoadingAttorney(false))
//   })
//     .catch((err) => {
//       const getFutureEngagements = handleFutureEngagements(
//         err.data.listFutureConsultations.items.filter((x) => x)
//       )
//       dispatch(_getFutureEngagements(getFutureEngagements))
//     // return futureEngagements
//     })
//     .finally(() => {
//       dispatch(_isLoadingAttorney(false))
//     })
// }

export const fetchActiveRequestsByClientId = (clientId) => async (dispatch) => {
  dispatch(_isLoading(true))
  const requestData = await fetchActiveRequests(clientId)

  if (!requestData) {
    return
  }

  let allActiveRequests = [
    ...requestData?.future
  ]
    ?.filter((activeRequest) => activeRequest?.consultation)
    ?.map((activeRequest) => ({
      id: activeRequest.id,
      case: activeRequest.case,
      consultation: activeRequest.consultation,
      name: `${activeRequest.case?.attorney?.firstName} ${activeRequest.case?.attorney?.lastName}`,
      date: moment(new Date(activeRequest.dateTimeScheduled)).format('MM.DD.YYYY'),
      time: moment(new Date(activeRequest.dateTimeScheduled)).format('hh:mmA'),
      dateTime: activeRequest?.dateTimeScheduled,
      price: activeRequest.case.payment
        ? activeRequest.case.payment.amount
        : '',
      stars: activeRequest.case.rating
        ? activeRequest.case.rating.overallScore
        : null,
      // textChatlog: activeRequest.textChatlog,
      avatarUrl: activeRequest.case?.attorney?.avatarUrl,
      email: activeRequest.case?.attorney?.emailWork,
      phoneNumber: activeRequest.case?.attorney?.phoneNumber,
      caseId: activeRequest.case.id,
      connectionMethod: activeRequest.case.consultationConnectionMethod,
      legalAdviceDescription: activeRequest.case?.legalAdviceDescription,
      areaOfLaw: activeRequest.case.areaOfLaw,
      consultationId: activeRequest.consultation.id,
      attorneyId: activeRequest.case?.attorney?.id,
      clientId: activeRequest.case?.client?.id,
      status: activeRequest.consultation.status
    }))

  const inProgressConsultation = allActiveRequests.filter(
    (consultation) =>
      (consultation?.status === 'InProgress' ||
        consultation?.status === 'Paused') &&
      consultation?.consultation?.status !== 'Canceled'
  )

  const otherConsultation = allActiveRequests.filter(
    (consultation) =>
      consultation?.status !== 'InProgress' &&
      consultation?.status !== 'Paused' &&
      consultation?.consultation?.status !== 'Canceled'
  )

  allActiveRequests = [...inProgressConsultation, ...otherConsultation]

  //  ************  for future request ************
  const getFutureRequests = allActiveRequests.filter((x) => {
    const date = new Date(x.dateTime)
    return (
      date.getTime() > Date.now() - 60 * 60 * 1000 &&
      x.status !== 'Completed' &&
      x.status !== 'Canceled'
    )
  })
  const futureRequests = getFutureRequests.sort((l, r) => {
    const leftDate = new Date(l.time)
    const rightDate = new Date(r.time)
    return leftDate.getTime() - rightDate.getTime()
  })

  //  ************  for active request ************
  const getActiveRequests = allActiveRequests.filter((x) => {
    const date = new Date(x.dateTime)
    return (
      date.getTime() < Date.now() - 60 * 60 * 1000 &&
      x.status !== 'Completed' &&
      x.status !== 'Canceled'
    )
  })
  const activeRequests = getActiveRequests.sort((l, r) => {
    const leftDate = new Date(l.dateTime)
    const rightDate = new Date(r.dateTime)
    return leftDate.getTime() - rightDate.getTime()
  })

  //  ************  for past request ************
  const getPastRequests = requestData?.future?.filter((x) => x.consultation.status === 'Completed')
  const tmp = []
  for (const item of getPastRequests) {
    tmp.push({
      consultationConnectionMethod: item.case.consultationConnectionMethod,
      length: (item.consultation.actualLength / 60) >= 1 ? (item.consultation.actualLength / 60).toFixed(2) + ' minutes' : 'less than a minute',
      time: item.dateTimeScheduled || item.createdAt,
      attorney: item.case.attorney,
      areaOfLaw: item.case.areaOfLaw,
      legalAdviceDescription: item.case.legalAdviceDescription,
      ratings: item.case.rating,
      payment: item.case.payment
    })
  }
  const pastRequests = tmp.sort((a, b) => moment(b.time) - moment(a.time))

  dispatch(_allRequests({ activeRequests, futureRequests, pastRequests }))
  dispatch(_isLoading(false))
}

// export const fetchPastInteractions = (clientId) => async (dispatch) => {
//   try {
//     const response = await Promise.all([
//       API.graphql(
//         graphqlOperation(listImmediateConsultations, {
//           limit: 1000000000,
//           filter: {
//             clientId: {
//               eq: clientId
//             }
//           }
//         })
//       ),
//       API.graphql(
//         graphqlOperation(listFutureConsultations, {
//           limit: 1000000000,
//           filter: {
//             attorneyId: {
//               eq: clientId
//             }
//           }
//         })
//       )
//     ]
//     )

//     const { listImmediateConsultations: { items: immediate } } = response[0].data
//     const { listFutureConsultations: {items: future} } = response[1].data;

//     console.log('!======immediate', response[0].data)
//     console.log('!======future', response[1].data)
//     // future: future.filter(item => item.consultation.status === 'Completed'),
//     const data = immediate.filter(item => item.consultation.status === 'Completed')
//     const tmp = []
//     for (const item of data) {
//       tmp.push({
//         consultationConnectionMethod: item.case.consultationConnectionMethod,
//         length: (item.consultation.actualLength / 60) >= 1 ? (item.consultation.actualLength / 60).toFixed(2) + ' minutes' : 'less than a minute',
//         time: item.dateTimeScheduled || item.createdAt,
//         client: item.case.client,
//         areaOfLaw: item.case.areaOfLaw,
//         legalAdviceDescription: item.case.legalAdviceDescription,
//         ratings: item.case.rating,
//         payment: item.case.payment
//       })
//     }
//     // setPastCases(tmp.sort((a,b) => moment(b.time) - moment(a.time)));
//     dispatch(_pastEngagementsList(tmp.sort((a, b) => moment(b.time) - moment(a.time))))
//   } catch (error) {
//     // errorHandler(error);
//     return null
//   }
// }

export default ConsultationReducer.reducer
