import { API, graphqlOperation } from 'aws-amplify'
import { createConsultation as CreateConsultation, updatePayment as UpdatePayment } from 'graphql/mutations'
import { onBriefUpdateCase } from 'gql/subscriptions'
import {
  createImmediateConsultation as CreateImmediateConsultation,
  updateAttorney as UpdateAttorney,
  createFutureConsultation as CreateFutureConsultation,
  getTwilioKeys as GetTwilioKeys,
  managePayment as ManagePayment,
  captureStripePayment as CaptureStripePayment,
  sendConsultationSummaryEmail as SendConsultationSummaryEmail,
  attorneySubscription as AttorneySubscription
} from 'gql/mutations'

export const createConsultation = (connectionMethod, status) => {
  return API.graphql(
    graphqlOperation(CreateConsultation, {
      input: {
        connectionMethod,
        status
      }
    })
  )
}

export const createImmediateConsultation = (caseId, consultationId) => {
  return API.graphql(
    graphqlOperation(CreateImmediateConsultation, {
      input: {
        immediateConsultationCaseId: caseId,
        immediateConsultationConsultationId: consultationId
      }
    })
  )
}

export const createFutureConsultation = (caseId, consultationId, time) => {
  return API.graphql(
    graphqlOperation(CreateFutureConsultation, {
      input: {
        dateTimeScheduled: time,
        futureConsultationCaseId: caseId,
        futureConsultationConsultationId: consultationId
      }
    })
  )
}

export const onBriefUpdateCaseSubscription = (id) => {
  return API.graphql(
    graphqlOperation(onBriefUpdateCase, {
      caseAttorneyId: id
    })
  )
}

export const updatePayment = (updatePaymentInput) => {
  return API.graphql(
    graphqlOperation(UpdatePayment, { input: { ...updatePaymentInput } })
  )
}

export const updateAttorney = (id, attorney) => {
  return API.graphql(
    graphqlOperation(UpdateAttorney, {
      input: {
        ...attorney,
        id
      }
    })
  )
}

export const getTwilioKeys = (service, roomName, userId) => {
  return API.graphql(
    graphqlOperation(GetTwilioKeys, {
      input: {
        service,
        userId,
        roomName
      }
    })
  )
}

export const captureStripePayment = (
  paymentId,
  paymentIntentId,
  attorneyName,
  attorneyId,
  attorneyUserId
) => {
  return API.graphql(
    graphqlOperation(CaptureStripePayment, {
      input: {
        paymentId,
        paymentIntentId,
        attorneyName,
        attorneyId,
        attorneyUserId
      }
    })
  )
}

export const managePayment = ({
  operation,
  customerId,
  email,
  account,
  refreshUrl,
  returnUrl,
  type
}) => {
  return API.graphql(
    graphqlOperation(ManagePayment, {
      input: {
        operation,
        customerId,
        email,
        account,
        refreshUrl,
        returnUrl,
        type
      }
    })
  )
}

export const attorneySubscription = ({
  operation,
  subscriptionId,
  paymentMethodId,
  email,
  name
}) => {
  return API.graphql(
    graphqlOperation(AttorneySubscription, {
      input: {
        operation,
        subscriptionId,
        paymentMethodId,
        email,
        name
      }
    })
  )
}

export const updateAttorneySubscription = ({
  operation,
  oldPaymentMethodId,
  newPaymentMethodId,
  customerId
}) => {
  return API.graphql(
    graphqlOperation(AttorneySubscription, {
      input: {
        operation,
        oldPaymentMethodId,
        newPaymentMethodId,
        customerId
      }
    })
  )
}

export const sendConsultationSummaryEmail = (consultationId, caseId) => {
  return API.graphql(
    graphqlOperation(SendConsultationSummaryEmail, {
      input: {
        consultationId,
        caseId
      }
    })
  )
}
