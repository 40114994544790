import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import ReduxLogger from 'redux-logger'
import ReduxThunk from 'redux-thunk'

// **************** import reducers files *****************
import userReducer from './reducers/userReducer'
import commonReducer from './reducers/commonReducer'
import consultationReducer from './reducers/consultationReducer'
import schedualAppointmentReducer from './reducers/schedualAppointmentReducer'
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
  blacklist: []
}

const reducers = combineReducers({
  user: userReducer,
  common: commonReducer,
  consultation: consultationReducer,
  schedualAppointment: schedualAppointmentReducer
})

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
  middleware: [ReduxThunk, ReduxLogger]
})
const { dispatch } = store
const persistor = persistStore(store)

export { store, dispatch, persistor }
