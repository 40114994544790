export const ConsultationFlow = {
  COMMON: 'Common',
  ONBOARDING: 'Onboarding'
}

export const PaymentMethod = {
  FREE: 'Free'
}

export const ConnectionMethod = {
  TEXT: 'Text',
  VOICE: 'Voice',
  VIDEO: 'Video'
}

export const ConsultationStatus = {
  PENDING: 'Pending',
  SCHEDULED: 'Scheduled',
  READY: 'Ready',
  IN_PROGRESS: 'InProgress',
  COMPLETED: 'Completed',
  PAUSED: 'Paused',
  CANCELED: 'Canceled'
}

export const FutureConsultationStatus = {
  SCHEDULED: 'Scheduled',
  COMPLETE: 'Completed',
  CANCELED: 'Canceled'
}

export const DateTimeScheduledRequestBy = {
  CLIENT: 'Client',
  ATTORNEY: 'Attorney'
}

export const DateTimeScheduledRequestStatus = {
  PENDING: 'Pending',
  ACCEPT: 'Accept',
  REJECT: 'Reject'
}

export const BELL_URL =
  'https://legalq-global-assets.s3.amazonaws.com/audios/notification.mp3'

export const TERMS_OF_SERVICES_URL = 'https://legalq.io/consumer-terms-of-use'
export const PRIVACY_POLICY_URL = 'https://legalq.io/privacy-policy'
export const USER_AGREEMENT_URL = 'https://legalq.io/consumer-terms-of-use'

export const AUTO_SCHEDULE_FREE_LEAD_LIMIT = 3
