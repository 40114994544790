import styled from 'styled-components'

export const SideBarContainer = styled.div`
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  height: auto;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  left: 0;
  right: auto;
  width: 304px;
  top: 0;
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  box-shadow: none;
`

export const SideBarDiv = styled.div`
  text-align: center;
  margin-bottom: 1px;
`

export const MenuDivCon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 40px;
  margin-top: 24px;
`

export const MenuDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0px;
  padding-top: 10px;
  border-bottom: ${(props) => props.border && '1px solid #B8B8BE'};
  max-width: 240px;
  width: 100%;
  cursor: pointer;
`

export const TopLogo = styled.div`
  padding-top: 5px;
`

export const UserDetails = styled.div`
  display: block;
  font-weight: 400;
  letter-spacing: 0.149;
  font-size: 14px;
  background-color: '#37688e';
  height: 180px;
`

export const UserDetailsBackground = styled.div`
  padding-top: 10px;
  padding-bottom: 15px;
  color: white;
  background-color: #5390D2;
  height: 210px;
  margin-top: 103px;
`

export const UserData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;
  margin-top: 67px;
`

export const UserDataSeparator = styled.hr`
  width: 224px;
  height: 0px;
  border: 1px solid #D8E6ED;
  margin-top: 16px;
`

export const UserDataTitle = styled.div`
  font-style: normal;
  font-family: Rubik;
  font-weight: 500;
  font-size: 21px;
  line-height: 21px;
`

export const ConsultationTimeCont = styled.div`
  background-color: #e53e3e;
  padding: 40px 35px 40px 35px;
`

export const ConsultationTime = styled.span`
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: white;
`

export const JoinConsultation = styled.div`
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #FFFFFF;
`

export const Span = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  margin-top: 20px;
  margin-left: 15px;
  display: flex;
  align-self: center;
  color: ${({ selected }) => selected == 'true' ? '#287EC7' : '#75767D'};
`

export const Img = styled.img`
  margin-left: 10px;
  margin-top: 20px;
`

export const UserSpan = styled.span`
  display: flex;
  font-family: Inter;
  justify-content: center;
  color:#fff;
`

export const Logo = styled.img`
  width: 175px;
  height: 48px;
  margin-top: 24px;
  margin-left: 30px;
`
// export const MainDiv = styled.div`
//   &:hover{
//     opacity: 0.8;
//   }
// `

export const UserImg = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 50%;
  object-fit: cover;

`
export const PlusImg = styled.img`
  width: 30px;
  height: 30px;
  /* border-radius: 50%; */
  object-fit: cover;
`

export const Loader = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 50%;
  /* object-fit: cover; */
  display: flex;
  justify-content: center;
  align-items: center;
`
export const UserInput = styled.input`
  width: 140px;
  height: 140px;
  /* border-radius: 50%; */
  object-fit: cover;
`
export const UploadOverlay = styled.div`
  /* position: absolute; */
  width: 140px;
  height: 140px;
  /* top: 110 !important;
  left: 82 !important; */
  background-color: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  transition: all 0.25s;
  opacity: 0.1;
  border-radius: 100px;
  &:hover{
    opacity: 0.7;
    cursor: pointer;
  }
`;