import styled, { createGlobalStyle } from 'styled-components'
import { styledTheme } from 'styles/styledTheme'
export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${styledTheme.colors.secondaryDark};
  }
`
export const MainLayoutSection = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
`

export const SidebarSection = styled.section`
  position: fixed;
  width: 304px;
  left: 0px;
  top: 0px;
  bottom: 0px;
`
export const OutletSection = styled.section`
  margin-left: 304px;
  width: calc(100% - 304px);
  background-color: ${styledTheme.colors.mainBackground};
`
export const Container = styled.section`
/* max-width: 1000px;
max-height: 1000px; */
`
