import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { PATH_AUTH } from 'routes/paths'

function AuthGuard ({ children }) {
  const isAuthenticated = useSelector(state => state?.user?.userInfo?.token)
  if (!isAuthenticated) {
    return <Navigate to={PATH_AUTH.login} />
  }

  return <>{children}</>
}

export default AuthGuard
