import axios from 'axios'

export const api = axios.create({
  // baseURL: 'http://localhost:3001/'
  baseURL: 'https://develop-api.legalq.io/'
})

export const post = async (url, payload) => {
  try {
    const response = await api.post(url, payload)
    return response.data
  } catch (err) {
    return err.message
  }
}
