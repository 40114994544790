// import { RotatingLines } from "react-loader-spinner";
import { useSelector } from 'react-redux'

// ---------- local import components ----------
import './loader.css'

import React from 'react'

function Loader () {
  const { common } = useSelector((state) => state)
  const { isLoading } = common

  return isLoading && <div className='loader-style' />
}

export default Loader
