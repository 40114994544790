import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { PATH_AUTH, PATH_PAGE } from 'routes/paths'

function GuestGuard ({ children }) {
  const { token, navigatePage } = useSelector(state => state?.user?.userInfo)
  if (token) {
    if (navigatePage === 'appointment') {
      return <Navigate to={PATH_AUTH.appointment} />
    }
    return <Navigate to={PATH_PAGE.home} />
  }

  return <>{children}</>
}

export default GuestGuard
