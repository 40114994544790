import {
  managePayment,
  captureStripePayment,
  updatePayment,
  sendConsultationSummaryEmail,
  attorneySubscription,
  updateAttorneySubscription
} from 'services/aws-queries-helper'
import { errorHandler } from 'utils/errorHandler'

const createAccount = (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = {
        operation: 'create',
        email
      }
      const response = await managePayment(body)
      const responseData = response.data.managePayment
      if (responseData.statusCode !== 200) {
        reject(null)
      }
      resolve(JSON.parse(responseData.body))
    } catch (err) {
      reject(null)
    }
  })
}

const onboardAccount = (account, type) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = {
        operation: 'onboard',
        account,
        returnUrl: `${window.location.origin}/dashboard/my-account`,
        refreshUrl: `${window.location.origin}/dashboard/my-account`,
        type
      }
      const response = await managePayment(body)
      const responseData = response.data.managePayment
      if (responseData.statusCode !== 200) {
        reject(null)
      }
      resolve(JSON.parse(responseData.body))
    } catch (err) {
      errorHandler(err)
      reject(err)
    }
  })
}

const retrieveAccount = (account) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = {
        operation: 'retrieve',
        account
      }
      const response = await managePayment(body)
      const responseData = response.data.managePayment
      if (responseData.statusCode !== 200) {
        reject(null)
      }
      resolve(JSON.parse(responseData.body))
    } catch (err) {
      errorHandler(err)
      reject(err)
    }
  })
}

const capturePayment = async (
  paymentId,
  paymentIntentId,
  attorneyName,
  attorneyId,
  attorneyUserId,
  paymentMethod,
  caseId,
  consultationId
) => {
  if (paymentMethod === 'PromoCredits' || !paymentIntentId) {
    await sendConsultationSummaryEmail(consultationId, caseId)
    return
  }

  await captureStripePayment(
    paymentId,
    paymentIntentId,
    attorneyName,
    attorneyId,
    attorneyUserId
  )

  const updatePaymentInput = {
    id: paymentId,
    paymentStatus: 'Payed'
  }
  await updatePayment(updatePaymentInput)

  await sendConsultationSummaryEmail(consultationId, caseId)
}

const retrieveCustomer = async (customerId) => {
  try {
    const body = {
      operation: 'customer',
      customerId
    }
    const response = await managePayment(body)
    const responseData = response.data.managePayment

    if (responseData.statusCode !== 200) {
      return null
    }
    return JSON.parse(responseData.body)
  } catch (err) {
    errorHandler(err)
    return err
  }
}

const createAttorneySubscription = async ({ paymentMethodId, email, name }) => {
  try {
    const body = {
      operation: 'subscribe',
      paymentMethodId,
      email,
      name
    }
    const response = await attorneySubscription(body)
    const responseData = response.data.attorneySubscription
    if (responseData.statusCode !== 200) {
      return null
    }
    return JSON.parse(responseData.body)
  } catch (err) {
    errorHandler(err)
    return err
  }
}

const updateAttorneyPaymentMethod = async ({
  oldPaymentMethodId,
  newPaymentMethodId,
  customerId
}) => {
  const body = {
    operation: 'update',
    oldPaymentMethodId,
    newPaymentMethodId,
    customerId
  }
  const response = await updateAttorneySubscription(body)
  const responseData = response.data.attorneySubscription
  if (responseData.statusCode !== 200) {
    throw responseData?.errors
  }
}

const cancelAttorneySubscription = async (subscriptionId) => {
  try {
    const body = {
      operation: 'unsubscribe',
      subscriptionId
    }
    const response = await attorneySubscription(body)
    const responseData = response.data.attorneySubscription
    if (responseData.statusCode !== 200) {
      return null
    }

    return JSON.parse(responseData.body)
  } catch (err) {
    errorHandler(err)
    return err
  }
}

const retrieveAttorneySubscription = async (subscriptionId) => {
  try {
    const body = {
      operation: 'retrieve',
      subscriptionId
    }
    const response = await attorneySubscription(body)
    const responseData = response.data.attorneySubscription
    if (responseData.statusCode !== 200) {
      return null
    }

    return JSON.parse(responseData.body)
  } catch (err) {
    errorHandler(err)
    return err
  }
}

const checkIsAttorneySubscribed = async (userInfo) => {
  const stripeSubscription = await retrieveAttorneySubscription(
    userInfo.stripeSubscriptionId
  )

  return (
    userInfo &&
    userInfo.stripeSubscriptionId &&
    stripeSubscription &&
    stripeSubscription.status === 'active'
  )
}

export default {
  createAccount,
  retrieveAccount,
  onboardAccount,
  capturePayment,
  retrieveCustomer,
  createAttorneySubscription,
  cancelAttorneySubscription,
  retrieveAttorneySubscription,
  checkIsAttorneySubscribed,
  updateAttorneyPaymentMethod
}
