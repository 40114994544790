// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
  signUp: '/signup',
  resetPassword: '/reset-password',
  verify: '/verify',
  forget: '/forget',
  appointment: '/appointment-form'
}

export const PATH_PAGE = {
  dashboard: '/dashboard',
  home: '/home',
  dashboardAppointment: '/create-new-appointment',
  schedulemeeting: '/schedulemeeting',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  comingSoon: '/coming-soon',
  pricing: '/pricing',
  payment: '/payment',
  page404: '/404',
  page500: '/500'
}
