import { createSlice } from '@reduxjs/toolkit'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import { get } from 'lodash'

// ---------- local import components ----------
import { isLoading, showToast } from './commonReducer'
import { api } from 'services/restServices'
import { createClient, createPayment } from 'graphql/mutations'
import { listClients } from 'graphql/queries'

const initialState = {
  userInfo: {},
  clientDetail: {},
  clientsList: [],
  paymentDetail: {},
  isLoading: false
}

export const UserReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    _loginUser (state, { payload }) {
      state.userInfo = payload
    },

    _logOutUser (state) {
      state.userInfo = {}
      state.clientDetail = {}
      state.clientsList = {}
      state.paymentDetail = {}
    },

    _signUpUser (state, { payload }) {
      state.userInfo = { ...state.userInfo, ...payload }
    },

    userData (state, { payload }) {
      state.userInfo = payload
    },

    _setClientDetail (state, { payload }) {
      state.clientDetail = payload
    },

    _setClientsList (state, { payload }) {
      state.clientsList = payload
    },

    _setPaymentDetail (state, { payload }) {
      state.paymentDetail = payload
    }
  }
})

export const { _loginUser, _logOutUser, _signUpUser, userData, _setClientDetail, _setClientsList, _setPaymentDetail } = UserReducer.actions

export const loginUser = (payload) => async (dispatch) => {
  // debugger
  dispatch(isLoading(true))
  try {
    const response = await Auth.signIn(payload?.email, payload?.password)
    // const client = await API.graphql(getClient, { id: response.username });
    // console.log('clientsResponse response-----1', client)

    if (response) {
      dispatch(isLoading(true))
      const signedInUserSession = response.signInUserSession
      const userData = {
        userId: response.username,
        email: response.attributes.email,
        phone_number: response.attributes.phone_number,
        token: signedInUserSession.accessToken.jwtToken,
        cognitoId: signedInUserSession.idToken.jwtToken
      }
      const userGroup = signedInUserSession.idToken.payload['cognito:groups']
      if (userGroup) {
        const clientsResponse = await API.graphql(graphqlOperation(listClients, {
          limit: 1000000000,
          input: {
            cognitoId: {
              eq: response.username
            }
          }
        }))
        setTimeout(() => {
          dispatch(showToast({ type: 'success', message: 'successfully Login' }))
        }, 800)
        const items = clientsResponse?.data?.listClients?.items[0]
        if (items) {
          dispatch(_setClientDetail(items))
          if (items?.cases?.items?.length > 0) {
            return { ...userData, navigatePage: 'home' }
          } else {
            return { ...userData, navigatePage: 'appointment' }
          }
        } else {
          return { ...userData, navigatePage: 'appointment' }
        }
      }
    }
  } catch (err) {
    if (err.code === 'UserNotFoundException') {
      dispatch(showToast({ type: 'error', message: 'User does not exist.' }))
    } else {
      dispatch(showToast({ type: 'error', message: 'Incorrect username or password.' }))
    }
    return false
  }
}

export const signUpUser = (payload) => async (dispatch) => {
  dispatch(isLoading(true))
  try {
    await Auth.signUp(payload)
    dispatch(_signUpUser({ password: payload?.password, fullName: payload?.fullName }))
    dispatch(showToast({ type: 'success', message: 'successfully signup' }))
    return true
  } catch (err) {
    if (err.code === 'UsernameExistsException') {
      dispatch(
        showToast({
          type: 'error',
          message:
            'User exists! Please click Sign In if you want to use this email!'
        })
      )
    } else {
      dispatch(showToast({ type: 'error', message: err.message }))
    }
    return false
  }
}

export const resendCode = (email) => async (dispatch) => {
  // dispatch(isLoading(true))
  try {
    const response = await Auth.resendSignUp(email)
    dispatch(showToast({ type: 'success', message: `resend otp at ${response?.CodeDeliveryDetails?.Destination}` }))
  } catch (err) {
    dispatch(showToast({ type: 'error', message: err }))
  } finally {
    dispatch(isLoading(false))
  }
}
export const upsertSignUpCredential = (payload) => async (dispatch) => {
  dispatch(isLoading(true))
  try {
    const signupCredential = {
      email: payload?.attributes?.email,
      phoneNumber: payload?.attributes?.phone_number,
      username: payload?.username,
      platform: 'client'
    }
    const result = await api.post('sign-up-credentials/upsert', signupCredential)
    dispatch(_signUpUser({ ...result.data, fullName: payload?.fullName }))
    // dispatch(isLoading(false))
    return true
  } catch (err) {
    dispatch(showToast({ type: 'error', message: err }))
    return false
  }
}

export const createClientDetail = (clientDetail) => async (dispatch) => {
  try {
    const createdResponse = await API.graphql(
      graphqlOperation(createClient, {
        input: clientDetail
      })
    )
    const clientDetails = get(createdResponse, 'data.createClient', {})
    dispatch(_setClientDetail(clientDetails))
    return true
  } catch (err) {
    dispatch(showToast({ type: 'error', message: err }))
  }
}

export const createFreePayment = (cognitoId) => async (dispatch) => {
  const currentDateTime = new Date()
  try {
    const input = {
      paymentMethod: 'Free',
      paymentStatus: 'Payed',
      dateTime: currentDateTime.toISOString(),
      amount: 0,
      calculatedAttorneyAmount: 0,
      owner: cognitoId
    }
    const response = await API.graphql(
      graphqlOperation(createPayment, { input })
    )

    if (response) {
      const paymentDetail = get(response, 'data.createPayment', {})
      dispatch(_setPaymentDetail(paymentDetail))
    }
  } catch (error) {
    dispatch(showToast({ type: 'error', message: 'Error while creating free payment record', error }))
  }
}

export const verifyCode = (payload) => async (dispatch) => {
  dispatch(isLoading(true))
  try {
    await Auth.confirmSignUp(payload?.email, payload?.code)
    dispatch(showToast({ type: 'success', message: 'successfully Verified' }))
    return true
  } catch (err) {
    if (err.code === 'CodeMismatchException' || err.code === 'ExpiredCodeException') {
      dispatch(showToast({ type: 'error', message: 'The code you entered is incorrect or expired' }))
    } else {
      dispatch(showToast({ type: 'error', message: err.message }))
    }
    return false
  }
}

export const sendEmail = (payload) => async (dispatch) => {
  dispatch(isLoading(true))
  try {
    await Auth.forgotPassword(payload?.email)
    dispatch(showToast({ type: 'success', message: 'send OTP on you phone.' }))
    // dispatch(userData(payload))
    return true
  } catch (err) {
    if (err.code === 'UserNotFoundException') {
      dispatch(showToast({ type: 'error', message: 'Username/client id combination not found.' }))
    } else if (err.code === 'InvalidParameterException') {
      dispatch(showToast({ type: 'error', message: 'Cannot reset password for the user as there is no registered/verified email or phone_number.' }))
    } else {
      dispatch(showToast({ type: 'error', message: err.code }))
    }
    return false
  }
}

export const sendNewPasswordWithOtp = (payload) => async (dispatch) => {
  dispatch(isLoading(true))
  const { email, code, password } = payload
  try {
    await Auth.forgotPasswordSubmit(email, code, password)
    dispatch(showToast({ type: 'success', message: 'password successfully changed.' }))
    // dispatch(userData(payload))
    return true
  } catch (err) {
    if (err.code === 'UserNotFoundException') {
      dispatch(showToast({ type: 'error', message: 'Username/client id combination not found.' }))
    } else if (err.code === 'CodeMismatchException') {
      dispatch(showToast({ type: 'error', message: 'Invalid verification code provided, please try again.' }))
    } else {
      dispatch(showToast({ type: 'error', message: err.code }))
    }
    return false
  }
}

export const logOutUser = (payload) => async (dispatch) => {
  dispatch(isLoading(true))
  dispatch(_logOutUser())
  dispatch(showToast({ type: 'success', message: 'user log out successfully' }))
}

export default UserReducer.reducer
