import { lazy, Suspense } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'

import LazyLoader from 'components/LazyLoader'
import Layout from '../Layout'
import AuthGuard from 'guards/authGuard'
import GuestGuard from 'guards/guestGuard'

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <Component {...props} />
    </Suspense>
  )
}

export default function Router () {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to='signup' replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <AuthContainer />
            </GuestGuard>
          )
        },
        {
          path: 'signup',
          element: (
            <GuestGuard>
              <AuthContainer />
            </GuestGuard>
          )
        },
        {
          path: 'verify',
          element: (
            <GuestGuard>
              <AuthContainer />
            </GuestGuard>
          )
        },
        {
          path: 'forget',
          element: (
            <GuestGuard>
              <AuthContainer />
            </GuestGuard>
          )
        },
        {
          path: 'appointment-form',
          element: (
            <AuthGuard>
              <AuthContainer />
            </AuthGuard>
          )
        }
        // { path: 'appointment-form', element: <AuthContainer /> }
      ]
    },

    // After Login Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <Layout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to='home' replace />, index: true },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'home', element: <Home /> },
        { path: 'create-new-appointment', element: <Home /> },
        { path: 'about', element: <About /> }
      ]
    },
    { path: '*', element: <Navigate to='/404' replace /> }
  ])
}

const AuthContainer = Loadable(lazy(() => import('../containers/AuthContainer')))
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')))
const Home = Loadable(lazy(() => import('../containers/HomeContainer')))
const About = Loadable(lazy(() => import('../pages/About')))
