import { API, Auth, graphqlOperation } from 'aws-amplify'
import get from 'lodash/get'
import moment from 'moment'
import { createCase, createClient, createConsultation } from 'graphql/mutations'
import { getAttorneyConsultationRequest, listFutureConsultations } from 'graphql/queries'
import {
  ConsultationFlow,
  ConsultationStatus
} from 'API'
import { api } from './restServices'

export async function createClientDetail (onboardingDetail) {
  const currentUser = await Auth.currentUserInfo()
  const clientDetail = {
    cognitoId: currentUser?.username,
    email: onboardingDetail.email,
    acceptsTCAndPP: true,
    firstName: onboardingDetail.firstName,
    lastName: onboardingDetail.lastName
  }
  const createdResponse = await API.graphql(
    graphqlOperation(createClient, {
      input: clientDetail
    })
  )
  return get(createdResponse, 'data.createClient', {})
}

export const initiateCaseCreation = async (onboardingStore) => {
  const consultationId = await consultationMutation(
    onboardingStore.connectionMethod,
    onboardingStore.dateTimeScheduled,
    onboardingStore.purchasedLength,
    onboardingStore.isOnboardingSession
  )
  const createdCase = await caseMutation(onboardingStore)
  return {
    consultationId,
    createdCase
  }
}

export const consultationMutation = async (connectionMethod, dateTimeScheduled, purchasedLength, isOnboardingSession) => {
  let consultationId = null
  try {
    const creatingConsultation = {
      input: {
        connectionMethod,
        status: dateTimeScheduled ? ConsultationStatus.Scheduled : ConsultationStatus.InProgress,
        purchasedLength: Number(purchasedLength),
        flow: isOnboardingSession ? ConsultationFlow.ONBOARDING : ConsultationFlow.COMMON
      }
    }
    const response = await API.graphql(graphqlOperation(createConsultation, creatingConsultation))
    consultationId = response.data.createConsultation.id
  } catch (err) {
    // return err
  }
  return consultationId
}

const caseMutation = async (onboardingStore) => {
  const { legalAdviceDescription, areaOfLaw, venueState, paymentId, connectionMethod, dateTimeScheduled, caseClientId } = onboardingStore
  let caseId = null
  try {
    const input = {
      caseClientId,
      legalAdviceDescription,
      areaOfLaw,
      venueState,
      casePaymentId: paymentId,
      consultationConnectionMethod: connectionMethod,
      futureConsultationDateTime: dateTimeScheduled || null
    }
    const response = await API.graphql(graphqlOperation(createCase, { input }))
    caseId = response.data.createCase.id
  } catch (e) {
    // return e
  }
  return caseId
}

export const createRequestAttorneyMatch = async (
  caseId,
  consultationId,
  targetAttorneyIds,
  isImmediateConsultation,
  targetTimeRange
) => {
  try {
    const response = await api.post('/request-attorney-match', {
      caseId,
      consultationId,
      targetAttorneyIds,
      isImmediateConsultation,
      targetTimeRange
    })
    return response.data
  } catch (error) {
  }
}

export const getAttorneyConsultationRequestsById = async (attorneyConsultationRequestId) => {
  try {
    await API.graphql(
      graphqlOperation(getAttorneyConsultationRequest, {
        id: attorneyConsultationRequestId
      })
    )
    return true
  } catch (error) {
    return false
  }
}

export const handlePastEngagements = (incomingPastEngagements) => {
  incomingPastEngagements = incomingPastEngagements.sort((l, r) => {
    const leftDate = new Date(l.createdAt)
    const rightDate = new Date(r.createdAt)
    return rightDate.getTime() - leftDate.getTime()
  })
  const formattedEngagements = incomingPastEngagements.map(
    (engagement) => {
      const dateObj = new Date(engagement.case.createdAt)
      return {
        id: engagement.id,
        name: `${engagement.case?.attorney?.firstName} ${engagement.case?.attorney?.lastName}`,
        date: moment(dateObj).format('MM.DD.YYYY'),
        time: moment(dateObj).format('hh:mmA'),
        phoneNumber: engagement.case?.attorney?.phoneNumber,
        legalAdviceDescription: engagement.case?.legalAdviceDescription,
        price: engagement.case.payment ? engagement.case.payment.amount : '',
        stars: engagement.case.rating
          ? engagement.case.rating.overallScore
          : null,
        textChatlog: engagement.textChatlog,
        avatarUrl: engagement.case?.attorney?.avatarUrl,
        caseId: engagement.case.id,
        connectionMethod: engagement.case.consultationConnectionMethod,
        areaOfLaw: engagement.case.areaOfLaw,
        consultationType: 'PAST'
      }
    }
  )
  return formattedEngagements
}

export const handleFutureEngagements = (entries) => {
  const futureEngagementsObj = {}
  const filteredEntries = entries.filter((x) => {
    const date = new Date(x.dateTimeScheduled)
    return (
      date.getTime() > Date.now() - 60 * 60 * 1000 &&
      x.consultation.status !== 'Completed' &&
      x.consultation.status !== 'Canceled'
    )
  })
  const sortedEntries = filteredEntries.sort((l, r) => {
    const leftDate = new Date(l.dateTimeScheduled)
    const rightDate = new Date(r.dateTimeScheduled)
    return leftDate.getTime() - rightDate.getTime()
  })
  if (sortedEntries.length > 0) {
    futureEngagementsObj.futureEngagements = sortedEntries
      ?.filter(futureConsultation => futureConsultation.dateTimeScheduledRequestStatus !== 'Pending')
      .map((engagement) => {
        const engagementDate = new Date(engagement.dateTimeScheduled)
        return {
          id: engagement.id,
          name: `${engagement.case?.attorney?.firstName} ${engagement.case?.attorney?.lastName}`,
          date: moment(engagementDate).format('MM.DD.YYYY'),
          time: moment(engagementDate).format('hh:mmA'),
          price: engagement.case.payment
            ? engagement.case.payment.amount
            : '',
          stars: engagement.case.rating
            ? engagement.case.rating.overallScore
            : null,
          textChatlog: engagement.textChatlog,
          avatarUrl: engagement.case?.attorney?.avatarUrl,
          phoneNumber: engagement.case?.attorney?.phoneNumber,
          caseId: engagement.case.id,
          connectionMethod: engagement.case.consultationConnectionMethod,
          legalAdviceDescription: engagement.case?.legalAdviceDescription,
          areaOfLaw: engagement.case.areaOfLaw,
          consultationId: engagement.consultation.id,
          attorneyId: engagement.case?.attorney?.id,
          consultationStatus: engagement.consultation.status,
          consultationType: 'FUTURE'
        }
      })

    futureEngagementsObj.rescheduleEngagements = sortedEntries
      ?.filter(futureConsultation => futureConsultation.dateTimeScheduledRequestStatus === 'Pending')
      .map((engagement) => {
        const engagementDate = new Date(engagement.dateTimeScheduled)
        return {
          id: engagement.id,
          name: `${engagement.case?.attorney?.firstName} ${engagement.case?.attorney?.lastName}`,
          date: moment(engagementDate).format('MM.DD.YYYY'),
          time: moment(engagementDate).format('hh:mmA'),
          price: engagement.case.payment
            ? engagement.case.payment.amount
            : '',
          stars: engagement.case.rating
            ? engagement.case.rating.overallScore
            : null,
          textChatlog: engagement.textChatlog,
          avatarUrl: engagement.case?.attorney?.avatarUrl,
          caseId: engagement.case.id,
          connectionMethod: engagement.case.consultationConnectionMethod,
          areaOfLaw: engagement.case.areaOfLaw,
          consultationId: engagement.consultation.id,
          attorneyId: engagement.case?.attorney?.id,
          consultationStatus: engagement.consultation.status,
          consultationType: 'FUTURE'
        }
      })
  }
  futureEngagementsObj.fetchingEngagements = false
  return futureEngagementsObj
}

// const isActive = ({ status }) => {
//   return status === 'Scheduled' || status === 'InProgress' || status === 'Ready' || status === 'Paused'
// }

export const fetchActiveRequests = async (clientId) => {
  try {
    const response = await API.graphql(
      graphqlOperation(listFutureConsultations, {
        limit: 1000000000,
        filter: {
          clientId: {
            eq: clientId
          },
          status: {
            ne: 'Canceled'
          }
        }
      }
      ))

    const { listFutureConsultations: { items: future } } = response.data
    return {
      future
    }
  } catch (error) {
    // errorHandler(error)
    console.warn(error)
    return error
  }
}
