import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  showToastCheck: false,
  showToastAttributes: {}
}

export const CommonReducer = createSlice({
  name: 'common',
  initialState,
  reducers: {
    isLoading (state, { payload }) {
      state.isLoading = payload
    },

    showToast (state, { payload }) {
      state.isLoading = false
      state.showToastCheck = true
      state.showToastAttributes = payload
    },

    closeToast (state) {
      state.showToastCheck = false
      state.showToastAttributes = {}
    }
  }
})

export const { isLoading, showToast, closeToast } = CommonReducer.actions

export default CommonReducer.reducer
