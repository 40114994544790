import { createSlice } from '@reduxjs/toolkit'
import { API, graphqlOperation } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'

// ---------- local import components ----------
import { showToast } from './commonReducer'
import { getBriefFutureConsultation, getPayment, listImmediateConsultations } from 'graphql/queries'
import { dispatch } from 'redux/store'
import { getConsultation, markAttorneyJoined, markConsultationPaymentCaptured } from 'utils/consultation'
import { setConsultationTimestamp, updateConsultation } from 'graphql/mutations'
import moment from 'moment'
import { CLIENT_DISCONNECT_THRESHOLD } from 'utils/constants/timer'
import { ConsultationFlow } from 'utils/constants/enum/consultation'
import { connectTwilioAudioRoom, connectTwilioVideoRoom, disconnectTwilioVideoRoom } from 'services/twilioService'

import { store } from './../store'
import stripeService from 'services/stripeService'
import { _pastEngagementsList, _setActiveRequests } from './consultationReducer'
// import { useSelector } from 'react-redux';
const initialState = {
  isLoading: false,
  text: false,
  audio: false,
  video: false,
  room: null,
  isClientOnline: true,
  participant: null,
  modal: false,

  activeConsultation: null,
  activeCase: null,
  conversationClient: null,
  conversation: null,
  actualLength: 0,
  purchasedLength: 0,
  lastSeen: null,
  isSelfOnline: true,
  dialogData: '',
  open: false,

  attorneyJoinConsultation: false,
  activeRequests: [],
  pastCases: null

}

export const SchedualAppointmentReducer = createSlice({
  name: 'schedualAppointment',
  initialState,
  reducers: {

    setActiveConsultation (state, { payload }) {
      state.activeConsultation = payload
    },

    setActiveCase (state, { payload }) {
      state.activeCase = payload
    },

    setText (state, { payload }) {
      state.text = payload
    },

    setAudio (state, { payload }) {
      state.audio = payload
    },

    setVideo (state, { payload }) {
      state.video = payload
    },

    setRoom (state, { payload }) {
      state.room = payload
    },

    setConversationClient (state, { payload }) {
      state.conversationClient = payload
    },

    setConversation (state, { payload }) {
      state.conversation = payload
    },

    setActualLength (state, { payload }) {
      state.actualLength = payload
    },

    setPurchasedLength (state, { payload }) {
      state.purchasedLength = payload
    },

    setLastSeen (state, { payload }) {
      state.lastSeen = payload
    },

    setIsClientOnline (state, { payload }) {
      state.isClientOnline = payload
    },

    setIsSelfOnline (state, { payload }) {
      state.isSelfOnline = payload
    },

    setDialogData (state, { payload }) {
      state.dialogData = payload
    },

    setOpen (state, { payload }) {
      state.open = payload
    },

    setAttorneyJoinConsultation (state, { payload }) {
      state.attorneyJoinConsultation = payload
    },

    setActiveRequests (state, { payload }) {
      state.activeRequests = payload
    },

    setPastCases (state, { payload }) {
      state.pastCases = payload
    }

  }
})

export const {
  setText,
  setAudio,
  setVideo,
  setRoom,
  setActiveConsultation,
  setActiveCase,
  setConversationClient,
  setConversation,
  setActualLength,
  setPurchasedLength,
  setLastSeen,
  setIsClientOnline,
  setIsSelfOnline,
  setDialogData,
  setOpen,
  setAttorneyJoinConsultation,
  setActiveRequests,
  setPastCases

} = SchedualAppointmentReducer.actions

export const selectUsername = (state) => {
  return state.userMetadata.username
}

// New code here!

// (function SelectUsername() {
//   const consultation = useSelector(state => state.consultation);
//  console.log("D###############activeConsultation", consultation);
// })()
// const state = store.getState();
//  console.log("D###############activeConsultation", state);
export const fetchFutureConsultation = async (id) => {
  try {
    const response = await API.graphql(
      graphqlOperation(getBriefFutureConsultation, {
        id
      })
    )

    return response.data.getFutureConsultation
  } catch (error) {
    dispatch(showToast({ type: 'error', message: error }))
    return null
  }
}

export const logActivity = async () => {
  const { activeConsultation, isSelfOnline } = store.getState().schedualAppointment

  if (!activeConsultation || !activeConsultation.id) { return }

  try {
    markAttorneyJoined(activeConsultation?.id)

    await API.graphql(
      {
        ...graphqlOperation(setConsultationTimestamp, {
          input: {
            consultationId: activeConsultation.id,
            isAttorney: true
          }
        }),
        authMode: GRAPHQL_AUTH_MODE.AWS_IAM
      }
    )
    if (!isSelfOnline) {
      dispatch(showToast({ type: 'success', message: 'Connection resumed!' }))
      // setToast('Connection resumed!')
      dispatch(setIsSelfOnline(true))
    }
  } catch (err) {
    dispatch(showToast({ type: 'error', message: err?.errors[0]?.message }))
    // errorHandler(err)
    if (err && err.errors && err.errors.length > 0) {
      const msg = err.errors[0].message
      if (msg === 'Network Error') { dispatch(setIsSelfOnline(false)) }
    }
  }
}

export const pingClient = () => {
  const { activeCase, isClientOnline, attorneyJoinConsultation, lastSeen, isSelfOnline } = store.getState().schedualAppointment

  if (!isSelfOnline) { return }

  const difference = moment().diff(moment(lastSeen), 'seconds')
  if (!lastSeen) {
    dispatch(setDialogData({
      title: 'User Connection Alert',
      content: `It looks like the user might be having technical issues, feel free to give them a call at ${activeCase?.client?.phoneno ? activeCase?.client?.phoneno : ''}`
    }))
    dispatch(setOpen(true))
    setIsClientOnline(false)
  }

  if (difference < CLIENT_DISCONNECT_THRESHOLD) {
    setIsClientOnline(true)
    return
  }
  if (!isClientOnline || difference >= CLIENT_DISCONNECT_THRESHOLD) {
    if (activeCase?.consultation?.flow === ConsultationFlow.ONBOARDING && attorneyJoinConsultation) {
      handleComplete(true)
      return
    }
    dispatch(showToast({ type: 'success', message: 'Client has lost connection to LegalQ service. Attempting to reconnect...' }))
    // setToast('Client has lost connection to LegalQ service. Attempting to reconnect...');
    setIsClientOnline(false)
  }
}

export const onConsultationComplete = (length) => {
  const { activeCase, audio, video, pastCases } = store.getState().schedualAppointment
  const { activeRequests, pastRequests } = store.getState().consultation
  const tmp = [...activeRequests]
  const index = tmp.findIndex(item => item.case.id === activeCase.id)

  if (index !== -1) {
    const item = { ...tmp[index] }
    const newPastInteraction = {
      consultationConnectionMethod: item?.connectionMethod,
      length: (length / 60) >= 1 ? (length / 60).toFixed(2) + ' min(s)' : 'less than a minute',
      time: item?.dateTime,
      attorney: item.case.attorney,
      payment: activeCase?.payment,
      areaOfLaw: item?.areaOfLaw,
      legalAdviceDescription: item?.legalAdviceDescription,
      ratings: null
    }
    tmp.splice(index, 1)
    dispatch(_pastEngagementsList([newPastInteraction, ...pastRequests]))
    dispatch(_setActiveRequests(tmp))
  }

  // (video === true || audio === true) &&
  //   setRoom((prevRoom) => disconnectTwilioVideoRoom(prevRoom));
  dispatch(setText(false))
  dispatch(setAudio(false))
  dispatch(setVideo(false))

  const paymentId = activeCase.payment.id
  const caseId = activeCase.id
  const consultationId = activeCase.consultation.id
  showCompleteDialog(paymentId, caseId, consultationId)
  dispatch(setActiveConsultation(null))
  dispatch(setActiveCase(null))
}

export const capturePayment = (paymentId, caseId, consultationId) => {
  const { userInfo } = store.getState().user
  return API.graphql(
    graphqlOperation(getPayment, {
      id: paymentId
    })
  ).then(resp => {
    const stripeToken = resp.data.getPayment.stripeToken
    const paymentMethod = resp.data.getPayment.paymentMethod
    const attorneyName = `${userInfo.firstName} ${userInfo.lastName}`

    return stripeService.capturePayment(
      paymentId,
      stripeToken,
      attorneyName,
      userInfo.id,
      userInfo.cognitoId,
      paymentMethod,
      caseId,
      consultationId
    )
  })
}

export const handleComplete = (forced = false) => {
  if (forced === true) {
    updateActiveConsultation('Completed', onConsultationComplete)
    return
  }
  dispatch(setDialogData({
    title: 'Confirmation',
    content: 'Are you sure you want to end this consultation?',
    actions: [
      {
        text: 'Yes'
        // handler: () => {
        //   updateActiveConsultation('Completed', onConsultationComplete)
        // }
      },
      {
        text: 'No'
        // handler: () => setOpen(false)
      }
    ]
  }))
  dispatch(setOpen(true))
}

export const handleCompleteFun = () => {
  dispatch(updateActiveConsultation('Completed', onConsultationComplete))
}
export const handleReconnect = async () => {
  const { activeCase, audio, video } = store.getState().schedualAppointment
  const { userInfo } = store.getState().user
  let room
  if (audio) {
    setRoom((prevRoom) => disconnectTwilioVideoRoom(prevRoom))
    const room = await connectTwilioAudioRoom(activeCase.consultation.id, userInfo.cognitoId)
    setRoom(room)
  }
  if (video) {
    setRoom((prevRoom) => disconnectTwilioVideoRoom(prevRoom))
    const room = await connectTwilioVideoRoom(activeCase.consultation.id, userInfo.cognitoId)
    setRoom(room)
  }
}

export const showCompleteDialog = async (paymentId, caseId, consultationId) => {
  const { activeCase } = store.getState().schedualAppointment

  dispatch(setAttorneyJoinConsultation(false))
  // TODO add action ask attorney this is a success consultation
  dispatch(setDialogData({
    title: 'Consultation Update',
    content: 'This consultation has been completed. Thank you!'
  }))
  dispatch(setOpen(true))
  const newConsultation = await getConsultation(activeCase?.consultation?.id)
  if (newConsultation?.clientJoined && newConsultation?.attorneyJoined && !newConsultation?.paymentCaptured) {
    await capturePayment(paymentId, caseId, consultationId)
    markConsultationPaymentCaptured(consultationId)
  }
}

export const updateActiveConsultation = (status, cb) => async (dispatch) => {
  const id = store.getState().schedualAppointment.activeConsultation?.id
  try {
    const input = {
      id,
      status
    }
    const response = await API.graphql(
      graphqlOperation(updateConsultation, {
        input
      })
    )
    const length = 18
    if (response.data) {
      if (cb) { cb(response.data.updateConsultation.actualLength) }
    }
    return
  } catch (error) {
    dispatch(showToast({ type: 'error', message: error }))
    // errorHandler(error)
    console.log('Update Consultation Error', error)
  }
}

export default SchedualAppointmentReducer.reducer
