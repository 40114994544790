export const styledTheme = {
  fonts: {
    Inter: 'Inter'
  },
  colors: {
    activeBoxColor: '#D9F2FF',
    grayTitle: '#75767D',
    primary: '#216FB6',
    secondary: '#5390D2',
    secondaryDark: '#287EC7',
    secondaryDarker: '#225AA9',
    blue700: '#1C3C8B',
    default: '#B8B8BE',
    defaultLight: '#F3F2F2',
    defaultDark: '#9B9CA5',
    defaultDarker: '#64656C',
    info: '#D8E1EA',
    infoLight: '#D9E6ED',
    infoDark: '#91A5BA',
    main: '#ECFAF7',
    mainLight: '#F4FBf8',
    mainDark: '#DEF2EE',
    warning: '#EF7676',
    dark: '#26272C',
    white: '#ffffff',
    borderColor: '#d8e6ed',
    primaryGreen: '#42B883',
    primaryBlack: '#26272C',
    primaryGray: '#96ACB9',
    backgroundBlue: '#ECF5FA',
    mainBackground: '#F0F5F8',
    gray100: '#EDF2F7',
    gray200: '#E2E8F0',
    gray300: '#CBD5E0',
    gray400: '#A0AEC0',
    blue500: '#3182CE',
    gray800: '#1A202C',
    gray700: '#2D3748',
    gray500: '#718096',
    gray600: '#4A5568',
    red400: '#F56565',
    red500: '#E53E3E',
    red600: '#FF3D00',
    robinEggBlue: '#00ADEF',
    contentBackground: '#F0F5F8',
    secondaryGray: '#BBC9D5',
    black: '#000000',
    green400: '#48BB78',
    hover: {
      primary: '#1B4E7E',
      secondary: '#287EC7'
    },
    scrollLight: '#D8E1EA',
    scrollDark: '#91A5BA'
  },
  shadow: {
    blueBelow:
        '0px 16px 30px -10px rgba(70, 166, 247, 0.5), 0px 20px 20px -20px #46A6F7'
  }
}
